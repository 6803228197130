import AuthForm, { STATE_LOGIN } from '../components/Auth/AuthForm';
import React from 'react';
import { Alert, Card, Col, Row } from 'reactstrap';
import axios from '../api';
const FormValidators = require('../components/Auth/validate');
const validateSignUpForm = FormValidators.validateSignUpForm;
const validateLoginForm = FormValidators.validateLoginForm;
const zxcvbn = require('zxcvbn');
const md5 = require('md5');

class AuthPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      admin: {
        email: '',
        password: '',
        pwconfirm: '',
      },
      btnTxt: 'show',
      type: 'password',
      score: 0,
      showError: false,
    };

    this.pwMask = this.pwMask.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitSignup = this.submitSignup.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.pwHandleChange = this.pwHandleChange.bind(this);
  }

  handleChange(event) {
    event.preventDefault();
    const field = event.target.name;
    const admin = this.state.admin;
    admin[field] = event.target.value;

    this.setState({
      admin,
    });
  }

  pwHandleChange(event) {
    event.preventDefault();
    const field = event.target.name;
    const admin = this.state.admin;
    admin[field] = event.target.value;

    this.setState({
      admin,
    });

    if (event.target.value === '') {
      this.setState(state =>
        Object.assign({}, state, {
          score: 'null',
        }),
      );
    } else {
      var pw = zxcvbn(event.target.value);
      this.setState(state =>
        Object.assign({}, state, {
          score: pw.score + 1,
        }),
      );
    }
  }

  submitSignup(admins) {
    var params = {
      adminAccount: admins.adminAccount,
      password: admins.password,
    };
    axios
      .post('/secret/admins/signup', params)
      .then(res => {
        if ((res.code = 200)) {
          localStorage.setItem('secretToken', res.data.data.secretToken);
          localStorage.setItem('commonToken', res.data.data.commonToken);
          localStorage.isAuthenticated = true;
          this.props.history.push('/login');
        } else {
          this.setState({
            errors: { message: res.data.message },
          });
        }
        return res.data;
      })
      .catch(err => {
        console.log('Sign up data submit error: ', err);
      });
  }

  submitSignin(admins) {
    var params = {
      adminAccount: admins.adminAccount,
      password: md5(admins.password),
    };
    axios
      .post('/secret/admins/signin', params)
      .then(res => {
        if ((res.code = 200)) {
          localStorage.setItem('secretToken', res.data.data.secretToken);
          localStorage.setItem('commonToken', res.data.data.commonToken);
          localStorage.isAuthenticated = true;
          this.props.history.push('/');
        } else {
          this.setState({
            errors: { message: res.data.message },
          });
        }
        return res.data;
      })
      .catch(err => {
        this.setState({
          showError: true,
        });
        console.log('Sign in data submit error: ', err);
      });
  }

  validateForm(event) {
    event.preventDefault();
    if (this.props.authState === 'SIGNUP') {
      var payloadSignup = validateSignUpForm(this.state.admin);
      if (payloadSignup.success) {
        this.setState({
          errors: {},
        });
        var admins = {
          password: this.state.admin.password,
          adminAccount: this.state.admin.email,
        };
        this.submitSignup(admins);
      } else {
        const errors = payloadSignup.errors;
        this.setState({
          errors,
        });
      }
    } else {
      var payloadLogin = validateLoginForm(this.state.admin);
      if (payloadLogin.success) {
        this.setState({
          errors: {},
        });
        var admin = {
          password: this.state.admin.password,
          adminAccount: this.state.admin.email,
        };
        this.submitSignin(admin);
      } else {
        const errors = payloadLogin.errors;
        this.setState({
          errors,
        });
        console.log(this.state.error);
      }
    }
  }

  pwMask(event) {
    event.preventDefault();
    this.setState(state =>
      Object.assign({}, state, {
        type: this.state.type === 'password' ? 'input' : 'password',
        btnTxt: this.state.btnTxt === 'show' ? 'hide' : 'show',
      }),
    );
  }

  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      this.props.history.push('/login');
    } else {
      this.props.history.push('/signup');
    }
  };

  handleLogoClick = () => {
    this.props.history.push('/');
  };

  render() {
    return (
      <Row
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col md={6} lg={4}>
          <Card body>
            <AuthForm
              showLogo={true}
              authState={this.props.authState}
              onChangeAuthState={this.handleAuthState}
              onLogoClick={this.handleLogoClick}
              onSubmit={this.validateForm}
              onChange={this.handleChange}
              onPwChange={this.pwHandleChange}
              errors={this.state.errors}
              user={this.state.admin}
              score={this.state.score}
              btnTxt={this.state.btnTxt}
              type={this.state.type}
              pwMask={this.pwMask}
            />
          </Card>
          {
            <div>
              <Alert isOpen={this.state.showError} color="danger">
                Tài khoản hoặc mật khẩu không chính xác! Xin vui lòng kiểm tra
                lại!
              </Alert>
            </div>
          }
        </Col>
      </Row>
    );
  }
}

export default AuthPage;
