import React from 'react';
import PropTypes from 'utils/propTypes';

import { Media } from 'reactstrap';

import Avatar from 'components/Avatar';

const Notifications = ({ notificationsData }) => {
  return (
    notificationsData &&
    notificationsData.length &&
    notificationsData.map(
      (
        { notificationID, title, body, notificationImage, createdDate },
        index,
      ) => (
        <Media
          key={index}
          className="pb-2 pt-2"
          style={{ display: 'block', borderBottom: 'outset 1px' }}
        >
          <Media left className="align-self-center pr-3">
            <Avatar tag={Media} object src={notificationImage} alt="Avatar" />
          </Media>
          <Media
            body
            middle
            className="text-white align-self-center "
            style={{ fontStyle: 'italic', fontSize: 'medium' }}
          >
            {body}
          </Media>
          <Media right className="align-self-center">
            <small
              className="text-white"
              style={{ fontStyle: 'italic', fontSize: 'x-small' }}
            >
              {Date(createdDate)}
            </small>
          </Media>
        </Media>
      ),
    )
  );
};

Notifications.propTypes = {
  notificationsData: PropTypes.arrayOf(
    PropTypes.shape({
      notificationID: PropTypes.ID,
      notificationImage: PropTypes.string,
      body: PropTypes.string,
      createdDate: PropTypes.number,
      title: PropTypes.string,
    }),
  ),
};

Notifications.defaultProps = {
  notificationsData: [],
};

export default Notifications;
