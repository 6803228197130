import { STATE_LOGIN, STATE_SIGNUP } from 'components/Auth/AuthForm';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import './styles/reduction.scss';
import jwt_decode from 'jwt-decode';
import { createBrowserHistory as createHistory } from 'history';

const NewProductPage = React.lazy(() => import('pages/NewProductPage'));
const BreadPage = React.lazy(() => import('pages/BreadPage'));
const DrinksPage = React.lazy(() => import('pages/DrinksPage'));
const CakePage = React.lazy(() => import('pages/CakePage'));
const EventsPage = React.lazy(() => import('pages/EventsPage'));
const ItemDetails = React.lazy(() => import('pages/ItemDetails'));
const EventDetails = React.lazy(() => import('pages/EventDetails'));
const CartDashboardPage = React.lazy(() => import('pages/CartDashboardPage'));
const ImportExcelPage = React.lazy(() => import('pages/ImportExcelPage'));
const ImportBlogPage = React.lazy(() => import('pages/ImportBlogPage'));
const CreateNotiPage = React.lazy(() => import('pages/CreateNotiPage'));
const GoldenTimePage = React.lazy(() => import('pages/GoldenTimePage'));
const ManageCategoriesPage = React.lazy(() =>
  import('pages/ManageCategoriesPage'),
);

const getBasename = () => {
  return `/${process.env.REACT_APP_SOURCE_URL.split('/').pop()}`;
};

class App extends React.Component {
  history = createHistory(this.props);
  constructor(props) {
    super(props);
    this.state = {
      secretToken: localStorage.secretToken,
    };
  }

  componentDidMount() {
    if (this.state.secretToken === undefined) {
      this.history.push(`/login`);
    } else {
      const sertokenDecode = jwt_decode(this.state.secretToken);
      const expirationTime = sertokenDecode.exp * 1000 - 60000;
      if (Date.now() >= expirationTime) {
        this.logOUt();
      }
    }
  }

  logOUt = () => {
    localStorage.clear();
    this.history.push(`/login`);
    window.location.reload();
  };

  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <Switch>
          <LayoutRoute
            exact
            path="/login"
            layout={EmptyLayout}
            component={props => <AuthPage {...props} authState={STATE_LOGIN} />}
          />
          <LayoutRoute
            exact
            path="/signup"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_SIGNUP} />
            )}
          />

          <MainLayout breakpoint={this.props.breakpoint}>
            <React.Suspense fallback={<PageSpinner />}>
              <Switch>
                <Route
                  exact
                  path="/"
                  component={props => <NewProductPage {...props} />}
                />
                <Route
                  exact
                  path="/bread"
                  component={props => <BreadPage {...props} />}
                />
                <Route
                  exact
                  path="/drinks"
                  component={props => <DrinksPage {...props} />}
                />
                <Route
                  exact
                  path="/cake"
                  component={props => <CakePage {...props} />}
                />
                <Route
                  exact
                  path="/events"
                  component={props => <EventsPage {...props} />}
                />
                <Route
                  exact
                  path="/carts"
                  component={props => <CartDashboardPage {...props} />}
                />
                <Route
                  exact
                  path="/import-excel"
                  component={props => <ImportExcelPage {...props} />}
                />
                <Route
                  exact
                  path="/import-blog"
                  component={props => <ImportBlogPage {...props} />}
                />
                <Route
                  exact
                  path="/create-noti"
                  component={props => <CreateNotiPage {...props} />}
                />
                <Route
                  exact
                  path="/golden-time"
                  component={props => <GoldenTimePage {...props} />}
                />
                <Route
                  exact
                  path="/categories"
                  component={props => <ManageCategoriesPage {...props} />}
                />
                <Route exact path="/new-products/:productTypeID/:productId">
                  <ItemDetails />
                </Route>
                <Route exact path="/bread/:productTypeID/:productId">
                  <ItemDetails />
                </Route>
                <Route exact path="/cake/:productTypeID/:productId">
                  <ItemDetails />
                </Route>
                <Route exact path="/drinks/:productTypeID/:productId">
                  <ItemDetails />
                </Route>
                <Route exact path="/events/:eventId">
                  <EventDetails />
                </Route>
              </Switch>
            </React.Suspense>
          </MainLayout>
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
