import logo200Image from 'assets/img/logo/logo_200.png';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import WebsiteLink from 'components/WebsiteLink';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import PasswordStr from './PasswordStr';
import './style.css';

class AuthForm extends React.Component {
  componentDidMount() {
    const token = localStorage.secretToken;
    if (token !== undefined) {
      this.props.history.push(`/`);
    }
  }

  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  // handleSubmit = event => {
  //   event.preventDefault();
  // };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Đăng nhập';
    }

    if (!buttonText && this.isSignup) {
      return 'Đăng ký';
    }

    return buttonText;
  }

  render() {
    const {
      showLogo,
      children,
      onLogoClick,
      onSubmit,
      user,
      onChange,
      errors,
      type,
      onPwChange,
      score,
      btnTxt,
      pwMask,
    } = this.props;

    return (
      <div>
        {errors.message && <p style={{ color: 'red' }}>{errors.message}</p>}
        <Form onSubmit={onSubmit}>
          {showLogo && (
            <div className="text-center pb-4">
              <img
                src={logo200Image}
                className="rounded"
                style={{ width: 80, height: 80, cursor: 'pointer' }}
                alt="logo"
                onClick={onLogoClick}
              />
            </div>
          )}
          <FormGroup>
            <Label for="Email">E-mail</Label>
            <Input
              name="email"
              value={user.email}
              onChange={onChange}
              errortext={errors.email}
              type="email"
              placeholder="your@email.com"
            />
          </FormGroup>
          <FormGroup>
            <Label for="Password">Mật khẩu</Label>
            <Input
              name="password"
              value={user.password}
              onChange={onPwChange}
              errortext={errors.password}
              type={type}
              placeholder="Your password"
            />
          </FormGroup>
          {this.isSignup && (
            <div>
              <div className="pwStrRow">
                {score >= 1 && (
                  <div>
                    <PasswordStr score={score} />
                    <Button
                      onClick={pwMask}
                      style={{
                        marginTop: '10px',
                        fontSize: 'xx-small',
                        position: 'relative',
                        left: '50%',
                        transform: 'translateX(-50%)',
                      }}
                    >
                      {btnTxt}
                    </Button>
                  </div>
                )}
              </div>
              <FormGroup>
                <Label for="Confirm Password">Confirm Password</Label>
                <Input
                  name="pwconfirm"
                  value={user.pwconfirm}
                  onChange={onChange}
                  errortext={errors.pwconfirm}
                  type={type}
                  placeholder="Confirm your password"
                />
              </FormGroup>
            </div>
          )}
          {/* <FormGroup check>
            <Label check>
              <Input type="checkbox" />{' '}
              {this.isSignup ? 'Agree the terms and policy' : 'Remember me'}
            </Label>
          </FormGroup> */}
          <hr />
          <Button size="lg" className="border-0 mb-2" block type="submit">
            {this.renderButtonText()}
          </Button>

          {/* <div className="text-center pt-1">
            <h6>or</h6>
            <h6>
              {this.isSignup ? (
                <a
                  style={{ color: 'red' }}
                  href="#login"
                  onClick={this.changeAuthState(STATE_LOGIN)}
                >
                  Login
                </a>
              ) : (
                <a
                  style={{ color: 'red' }}
                  href="#signup"
                  onClick={this.changeAuthState(STATE_SIGNUP)}
                >
                  Signup
                </a>
              )}
            </h6>
          </div> */}

          <WebsiteLink style={{ color: '#b34b4b', fontWeight: 600 }}>
            <AiOutlineArrowLeft />
            Quay lại tiệm bánh Changi
          </WebsiteLink>
          {children}
        </Form>
      </div>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  onLogoClick: PropTypes.func,
  onChangeAuthState: PropTypes.func,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  user: PropTypes.object,
  score: PropTypes.number,
  btnTxt: PropTypes.string,
  type: PropTypes.string,
  pwMask: PropTypes.func,
  onPwChange: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
};

export default withRouter(AuthForm);
