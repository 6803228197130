import React from 'react';
import logo200Image from 'assets/img/logo/logo_200.png';
import { Row, Col, CardText, Navbar, Nav, NavItem } from 'reactstrap';
import WebsiteLink from 'components/WebsiteLink';
import {
  MdLocationOn,
  MdPhoneIphone,
  MdEmail,
  MdPhone,
  MdOutlineFacebook,
} from 'react-icons/md';
import { BsYoutube } from 'react-icons/bs';

const Footer = () => {
  return (
    <Navbar className="text-center" style={{ display: 'contents' }}>
      <Nav
        navbar
        style={{ backgroundColor: 'rgb(188 140 140)', height: 'auto' }}
      >
        <NavItem style={{ color: 'initial', padding: '20px' }}>
          <Row>
            <Col sm={3} md={3}>
              <WebsiteLink
                className="navbar-brand text-center"
                style={{ marginRight: 'unset' }}
              >
                <img
                  src={logo200Image}
                  width="72"
                  height="65"
                  alt="logoChangi"
                />
                <div className="text-white text-lg">Changi Bakery</div>
              </WebsiteLink>
            </Col>
            <Col sm={4} md={4}>
              <MdLocationOn style={{ fontSize: '19px' }} />
              <CardText
                style={{
                  color: 'initial',
                  fontWeight: 600,
                }}
              >
                Công ty CP TMDV Changi Bakery
                <br />
                <a
                  href="https://bit.ly/3DKL2Xc"
                  style={{
                    color: 'initial',
                    fontWeight: 300,
                  }}
                >
                  Kiot02, Tòa N04A, Khu Ngoại giao đoàn, Bắc Từ Liêm, Hà Nội
                </a>
              </CardText>
            </Col>
            <Col sm={2} md={2}>
              <CardText>
                <MdPhone style={{ fontSize: '19px' }} /> 0246.027.9764
              </CardText>
              <CardText>
                <MdPhoneIphone style={{ fontSize: '19px' }} /> 096.690.4664
              </CardText>
            </Col>
            <Col sm={3} md={3}>
              <CardText>
                <MdEmail style={{ fontSize: '19px' }} /> changibakery@gmail.com
              </CardText>
              <a
                href="https://www.facebook.com/tiembanhchangi"
                style={{
                  color: 'initial',
                  fontWeight: 300,
                  fontSize: '30px',
                }}
              >
                <MdOutlineFacebook />
              </a>{' '}
              <a
                href="https://www.youtube.com/channel/UCRnx_tqKu2mdCRkZsHibCgA"
                style={{
                  color: 'initial',
                  fontWeight: 300,
                  fontSize: '30px',
                }}
              >
                <BsYoutube />
              </a>
            </Col>
          </Row>
        </NavItem>
        <NavItem
          style={{
            color: 'initial',
            backgroundColor: 'bisque',
            padding: '10px',
          }}
        >
          Copyright © 2022 -{' '}
          <a
            href="http://changibakery.com/"
            style={{
              color: 'initial',
              fontWeight: 600,
            }}
          >
            Changi Bakery
          </a>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;
