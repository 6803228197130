import React from 'react';
import PropTypes from 'utils/propTypes';
import { Card, CardBody, CardFooter, CardImg } from 'reactstrap';
import { BsClock } from 'react-icons/bs';
import { VscLocation } from 'react-icons/vsc';

const EventsCard = ({
  eventImage,
  eventID,
  title,
  subtitle,
  startDate,
  endDate,
  voucherType,
  voucherValue,
  eventCodePrefix,
  ...restProps
}) => {
  var sDate = new Date(startDate);
  var eDate = new Date(endDate);

  return (
    <Card
      inverse
      className="event-card card-has-bg"
      {...restProps}
      style={{ backgroundImage: `url(${eventImage})` }}
    >
      <CardImg
        className="card-img d-none"
        src={eventImage}
        alt={title}
      ></CardImg>
      <div className="card-img-overlay d-flex flex-column">
        <CardBody className="justify-content-center align-items-center">
          <small className="card-meta mb-2">{title}</small>
          <h4 className="card-title mt-0">
            <span className="text-white">{subtitle}</span>
          </h4>
          <i>
            <BsClock />
            <small>
              {' '}
              {sDate.getDate()}/{sDate.getMonth() + 1}/{sDate.getFullYear()} -{' '}
              {eDate.getDate()}/{eDate.getMonth() + 1}/{eDate.getFullYear()}
            </small>
          </i>
          <div>
            <i>
              <VscLocation />
              <small>Hà Nội</small>
            </i>
          </div>
        </CardBody>
        <CardFooter>
          <div className="media">
            <CardImg
              className="mr-3 rounded-circle"
              src={
                voucherType === 1
                  ? 'https://cdn-icons-png.flaticon.com/512/726/726476.png'
                  : 'https://cdn-icons-png.flaticon.com/512/819/819216.png'
              }
              alt=""
              style={{ maxWidth: '50px' }}
            ></CardImg>
            <div className="media-body">
              <h6 className="my-0 text-white d-block">
                Giảm {voucherValue}
                {voucherType === 1 ? '%' : 'K'}
              </h6>
              <small>{eventCodePrefix}</small>
            </div>
          </div>
        </CardFooter>
      </div>
    </Card>
  );
};
EventsCard.propTypes = {
  eventImage: PropTypes.string,
  eventID: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  voucherType: PropTypes.number,
  voucherValue: PropTypes.number,
  eventCodePrefix: PropTypes.string,
};

export default EventsCard;
