import logo200Image from 'assets/img/logo/logo_200.png';
import sidebarBgImage from 'assets/img/sidebar/sidebar-9.jpg';
import SourceLink from 'components/SourceLink';
import React from 'react';
import {
  MdCake,
  MdLocalBar,
  MdEventAvailable,
  // MdAccountCircle,
  MdOutlineDriveFolderUpload,
  MdShoppingCart,
} from 'react-icons/md';
import { GiSlicedBread } from 'react-icons/gi';
import { AiFillNotification } from 'react-icons/ai';
import { ImBlogger } from 'react-icons/im';
import { RiTimerFlashFill } from 'react-icons/ri';
import { BiCategory, BiTrendingUp } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';
import { Nav, Navbar, NavItem, NavLink as BSNavLink } from 'reactstrap';
import bn from 'utils/bemnames';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const navItems = [
  {
    to: '/',
    name: 'sản phẩm mới',
    exact: true,
    Icon: BiTrendingUp,
  },
  { to: '/bread', name: 'bánh mì', exact: false, Icon: GiSlicedBread },
  { to: '/drinks', name: 'đồ uống', exact: false, Icon: MdLocalBar },
  { to: '/cake', name: 'bánh sinh nhật', exact: false, Icon: MdCake },
  { to: '/events', name: 'sự kiện', exact: false, Icon: MdEventAvailable },
  { to: '/carts', name: 'đơn hàng', exact: false, Icon: MdShoppingCart },
  // { to: '/login', name: 'login / signup', exact: false, Icon: MdAccountCircle },
  {
    to: '/import-excel',
    name: 'Cập nhật điểm',
    exact: false,
    Icon: MdOutlineDriveFolderUpload,
  },
  {
    to: '/import-blog',
    name: 'Bài đăng',
    exact: false,
    Icon: ImBlogger,
  },
  {
    to: '/create-noti',
    name: 'Tạo thông báo',
    exact: false,
    Icon: AiFillNotification,
  },
  {
    to: '/golden-time',
    name: 'Khung giờ vàng',
    exact: false,
    Icon: RiTimerFlashFill,
  },
  {
    to: '/categories',
    name: 'Phân loại',
    exact: false,
    Icon: BiCategory,
  },
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar style={{ justifyContent: 'center' }}>
            <SourceLink
              className="navbar-brand text-center"
              style={{ marginRight: 'unset' }}
            >
              <img
                src={logo200Image}
                width="150"
                height="140"
                alt="logoChangi"
              />
              <div className="text-white text-lg">Changi Bakery</div>
            </SourceLink>
          </Navbar>
          <Nav vertical>
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
