import { withRouter } from 'react-router-dom';
import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import Notifications from 'components/Notifications';
// import SearchInput from 'components/SearchInput';
import withBadge from 'hocs/withBadge';
import React from 'react';
import api from '../../api';
import {
  MdClearAll,
  MdExitToApp,
  MdNotificationsActive,
  MdNotificationsNone,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';
import Sticky from 'react-stickynode';

const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>5</small>,
})(MdNotificationsActive);

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.toggleNotificationPopover = this.toggleNotificationPopover.bind(this);
    this.toggleUserCardPopover = this.toggleUserCardPopover.bind(this);
    this.handleSidebarControlButton =
      this.handleSidebarControlButton.bind(this);
    this.state = {
      commonToken: localStorage.commonToken,
      secretToken: localStorage.secretToken,
      isOpenNotificationPopover: false,
      isNotificationConfirmed: false,
      isOpenUserCardPopover: false,
      notificationsData: [],
    };
    this._isMounted = false;
  }

  componentDidMount() {
    if (this.state.secretToken === undefined) {
      this.props.history.push(`/login`);
    } else {
      this.getNotifications(this.state.commonToken);
    }
  }

  async getNotifications(token) {
    await api
      .get(`v1/notifications`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': true,
        },
      })
      .then(response => {
        this.setState({ notificationsData: response.data.data });
      })
      .catch(err => {
        console.log(err);
      });
  }

  logOUt = () => {
    localStorage.clear();
    this.props.history.push(`/login`);
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  render() {
    const { isNotificationConfirmed, notificationsData } = this.state;

    return (
      <Sticky enabled={true}>
        <Navbar light expand className={bem.b('bg-white')}>
          <Nav navbar className="mr-2">
            <Button
              outline
              onClick={this.handleSidebarControlButton}
              aria-label="navbar"
            >
              <MdClearAll size={25} />
            </Button>
          </Nav>
          {/* <Nav navbar>
            <SearchInput />
          </Nav> */}

          <Nav navbar className={bem.e('nav-right')}>
            <NavItem className="d-inline-flex">
              <NavLink
                id="Popover1"
                className="position-relative"
                style={{ backgroundColor: 'transparent' }}
              >
                {isNotificationConfirmed ? (
                  <MdNotificationsNone
                    size={25}
                    className="text-secondary can-click"
                    onClick={this.toggleNotificationPopover}
                  />
                ) : (
                  <MdNotificationsActiveWithBadge
                    size={25}
                    className="text-secondary can-click animated swing infinite"
                    onClick={this.toggleNotificationPopover}
                  />
                )}
              </NavLink>
              <Popover
                className={bem.e('notification-popover')}
                placement="bottom"
                isOpen={this.state.isOpenNotificationPopover}
                toggle={this.toggleNotificationPopover}
                target="Popover1"
              >
                <PopoverBody>
                  <Notifications notificationsData={notificationsData} />/
                </PopoverBody>
              </Popover>
            </NavItem>

            <NavItem>
              <NavLink id="Popover2" style={{ backgroundColor: 'transparent' }}>
                <Avatar
                  onClick={this.toggleUserCardPopover}
                  className="can-click"
                />
              </NavLink>
              <Popover
                placement="bottom-end"
                isOpen={this.state.isOpenUserCardPopover}
                toggle={this.toggleUserCardPopover}
                target="Popover2"
                className="p-0 border-0"
                style={{ minWidth: 250 }}
              >
                <PopoverBody className="p-0 border-light">
                  <UserCard
                    title="Admin"
                    // subtitle="admin@admin.com"
                    // text="Last updated 3 mins ago"
                    className="border-light"
                  >
                    <ListGroup flush>
                      <ListGroupItem
                        style={{ backgroundColor: 'lightsalmon' }}
                        tag="button"
                        action
                        className="border-light"
                        onClick={this.logOUt}
                      >
                        <MdExitToApp /> Đăng xuất
                      </ListGroupItem>
                    </ListGroup>
                  </UserCard>
                </PopoverBody>
              </Popover>
            </NavItem>
          </Nav>
        </Navbar>
      </Sticky>
    );
  }
}

export default withRouter(Header);
